import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Badge, Button, Card, Table, Tag } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFetch } from '../../../../hooks/useFetch/useFetch';
import ProjectsAddModal from './ModalAddProject/ProjectsAddModal';
import './Users.scss';
import '../../pages.scss'
import TableLZM from '../../../components/design/table/TableLZM';
import { ButtonLZ } from '../../../components/design/Buttons/ButtonLZ';
import { TableFilter } from '../../../../common/utils/FilterUtils';


const Projects = () => {

  const { t } = useTranslation();
  const [addUserVisible, setAddUserVisible] = useState(false);
  const [updateData, setUpdateData] = useState(false);
	const [projects, setProjects] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useFetch(`${process.env.REACT_APP_API}/projects`, {}, [updateData], (data) => {

		setProjects(data);
    setIsLoading(isLoading);
	});

  const columns = [    
    {
      title: 'Nombre',
      key: 'project',
      dataIndex: ['_id', 'project'],
      filterDropdown: TableFilter,
      filterIcon: () => {
			  return <SearchOutlined />;
			},  
      onFilter: (value: any, record: any) => record._id?.project.includes(value),
      sorter: (a: any, b: any) => a._id?.project.localeCompare(b._id?.project),	
    },
    {
      title: 'Grupo',
      key: 'group',
      dataIndex: ['_id', 'group'],
      filterDropdown: TableFilter,
      filterIcon: () => {
			  return <SearchOutlined />;
			},  
      onFilter: (value: any, record: any) => record._id?.group.includes(value),
      sorter: (a: any, b: any) => a._id?.project.localeCompare(b._id?.group),	
    },
    {
      title: '#Locales',
      key: 'locationnumberOfLocationss',
      dataIndex: 'numberOfLocations',
      sorter: (a: any, b: any) => b.numberOfLocations - a.numberOfLocations	

    }
	]

  return (
    <>
      <Card className='main-card-content' >
        <h1 className='title-card'>{t('dictionary.businessManagement')}</h1>
        <div className="buttonWrapper" >
          <ButtonLZ color='yellow' onClick={() => setAddUserVisible(true)} type='primary'>
            <PlusOutlined/> {t('dictionary.newBusiness')}
          </ButtonLZ>
        </div>
        <TableLZM columns={columns} dataSource={projects} rowKey={'name'} loading={isLoading} />
        <ProjectsAddModal
          visible={addUserVisible}
          onFinish={() => {
            setAddUserVisible(false);
            setUpdateData(!updateData);
          }}
        />
      </Card>
    </>
	)
}

export default Projects;