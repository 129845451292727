import { Checkbox, Form, Input, Modal, Switch } from 'antd';
import 'moment/locale/es';
import { useAuth } from '../../../../../store/AuthContext/AuthContext';
import { getProjectStorage } from '../../../../../store/storageContext';
import { authFetch } from '../../../../../common/request/authFetch';
import { useState } from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';

export const NewKeywordModal = (props: {locationId: string, scope: string, websiteUri: string, onFinish: any}) => {

	//const [domainKeyword, setDomainKeyword] = useState(props.hasWeb);
	const [form] = Form.useForm();
	const { groupAccount } = useAuth();

	const onFormFinish = async (values: any) => {

		const data = {
			...values,
            scope: props.scope,
			websiteUri: props.websiteUri
        };

		await authFetch(`${process.env.REACT_APP_API}/projects/${getProjectStorage()}/keywords/${groupAccount}/${props.locationId}/terms`, { method: 'POST', data: data });
		props.onFinish();
	}

	return (

		<Modal open={true} onOk={() => form.submit()} onCancel={props.onFinish} afterClose={form.resetFields}>

			<h2>Añadir keyword</h2>
			<i style={{color:'Teal'}}><InfoCircleOutlined/> Tener en cuenta Categoría, localización, servicios y productos. Por ejemplo <b>"Clínica Dental"</b>, <b>"Córdoba"</b>, <b>"Ortodoncia"</b>, etc,...</i>
			<br/><br/>
			<Form layout='horizontal' form={form} onFinish={onFormFinish}>
				<Form.Item
					rules={[{ required: true, message:'Por favor, ingrese la keyword' }]}
					name='keyword'
					label='Keyword'>
					<Input/>
				</Form.Item>												
			</Form>			
		</Modal>
	)
}
