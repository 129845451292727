import { IdcardTwoTone, ProjectTwoTone, ShopTwoTone } from '@ant-design/icons';
import { RouterType } from '../types/routing/TypeRouter';
import GoogleLogin from '../view/components/model/googleLoginButton/GoogleLogin';
import GoogleClientsLayout from '../view/layouts/sideMenuLayout/3_ClientsLayout';
import GooglePosts from '../view/pages/menuContents/posts/GooglePosts';
import { QR } from '../view/pages/menuReviews/qr/QR';
import Users from '../view/pages/menuAdmin/4_1_users/Users';
import Projects from '../view/pages/menuAdmin/4_2_projects/Projects';
import Error403 from '../view/pages/common/errors/Error403';
import Error404 from '../view/pages/common/errors/Error404';
import Error500 from '../view/pages/common/errors/Error500';
import Login from '../view/pages/login/Login';
import RecoverPassword from '../view/pages/login/RecoverPassword';
import UserStep1Register from '../view/pages/register/UserStep1Register';
import UserStep2Vincular from '../view/pages/register/UserStep2Vincular';
import UserStep3Configurar from '../view/pages/register/UserStep3Configurar';
import Welcome from '../view/pages/register/Welcome';
import { Services } from '../view/pages/menuTech/Services';
import { TechLayout } from '../view/layouts/sideMenuLayout/5_TechLayout';
import { GoogleBusiness } from '../view/pages/menuInfo/pageLocations/GoogleLocations';
import { LocationsReviews } from '../view/pages/menuReviews/LocationsReviews/LocationsReviews';
import ContentsLayout from '../view/layouts/sideMenuLayout/ContentsLayout';
import { Notifications } from '../view/pages/Notifications/Notifications';
import { NotificationsLayout } from '../view/layouts/sideMenuLayout/NotificationsLayout';
import { ExternalWebs } from '../view/pages/menuTech/ExternalWebs';
import GoogleQuestions from '../view/pages/menuReviews/3_1_Reviews/GoogleQuestions';
import { WidgetWebs } from '../view/pages/menuInfo/pageWeb/WidgetsWeb';
import AppUsers from '../view/pages/menuAdmin/4_1_users/AppUsers';
import { Home } from '../view/pages/home/Home';
import { ReportsLayout } from '../view/layouts/sideMenuLayout/ReportsLayout';
import { LocationsSummary } from '../view/pages/menuReports/info/LocationsSummary';
import { ContentsSummary } from '../view/pages/menuReports/contents/ContentsSummary';
import { ReviewsSummary } from '../view/pages/menuReports/reviews/ReviewsSummary';
import { PageTemplates } from '../view/pages/menuReports/PageTemplates';
import { PublicReport } from '../view/pages/menuReports/report/PublicReport';
import { GooglePublishContent } from '../view/pages/menuContents/GooglePublishContent';
import { ConfigLayout } from '../view/layouts/sideMenuLayout/ConfigLayout';
import { Guests } from '../view/pages/menuUsers/Guests';
import { AdminLayout } from '../view/layouts/sideMenuLayout/AdminLayout';
import { GuestPermissions } from '../view/pages/menuUsers/GuestPermissions';
import { GuestRegister } from '../view/pages/register/guest/GuestRegister';
import { GoogleBizLayout } from '../view/layouts/sideMenuLayout/2_BusinessLayout';
import { GooglePostsScheduled } from '../view/pages/menuContents/posts/GooglePostsScheduled';
import { GoogleReviews } from '../view/pages/menuReviews/3_1_Reviews/GoogleReviews';
import { LocalSEO } from '../view/pages/menuInfo/pageSEO/LocalSEO';
import { MonitoringJobs } from '../view/pages/menuTech/MonitoringJobs';
import { KeyWords } from '../view/pages/menuUsers/keywords/KeyWords';


export const googleBizRoutes: Array<RouterType> = [
	{
		path: '/negocios/global',
		icon: ShopTwoTone,
		component: LocationsSummary
	},
	{
		path: '/negocios/locales',
		icon: ShopTwoTone,
		component: GoogleBusiness
	},
	{
		path: '/negocios/web',
		icon: ShopTwoTone,
		component: WidgetWebs
	},
	{
		path: '/negocios/seo',
		icon: ShopTwoTone,
		component: LocalSEO
	},
	{
		path: '/negocios/error403',
		icon: ShopTwoTone,
		component: Error403
	},
	{
		path: '/negocios/error404',
		icon: ShopTwoTone,
		component: Error404
	},
	{
		path: '/negocios/error500',
		icon: ShopTwoTone,
		component: Error500
	},
]

export const contentsRoutes: Array<RouterType> = [
	{
		path: '/contenidos/global',
		icon: ShopTwoTone,
		component: ContentsSummary
	},
	{
		path: '/contenidos/locales',
		icon: ShopTwoTone,
		component: GooglePublishContent
	},
	{
		path: '/contenidos/publicaciones',
		icon: ShopTwoTone,
		component: GooglePosts
	},
	{
		path: '/contenidos/programados',
		icon: ShopTwoTone,
		component: GooglePostsScheduled
	},
	{
		path: '/contenidos/error403',
		icon: ShopTwoTone,
		component: Error403
	},
	{
		path: '/contenidos/error404',
		icon: ShopTwoTone,
		component: Error404
	},
	{
		path: '/contenidos/error500',
		icon: ShopTwoTone,
		component: Error500
	},
]

export const googleClientsRoutes: Array<RouterType> = [
	{
		path: '/clientes/global',
		icon: ShopTwoTone,
		component: ReviewsSummary
	},
	{
		path: '/clientes/locationsreviews',
		icon: ShopTwoTone,
		component: LocationsReviews
	},
	{
		path: '/clientes/reviews',
		icon: ShopTwoTone,
		component: GoogleReviews
	},
	{
		path: '/clientes/questions',
		icon: ShopTwoTone,
		component: GoogleQuestions
	},
	{
		path: '/clientes/qr',
		icon: ShopTwoTone,
		component: QR
	},
	{
		path: '/clientes/mensajes',
		icon: ShopTwoTone,
		component: GoogleReviews /* Implementar */
	},
	{
		path: '/clientes/error403',
		icon: ShopTwoTone,
		component: Error403
	},
	{
		path: '/clientes/error404',
		icon: ShopTwoTone,
		component: Error404
	},
	{
		path: '/clientes/error500',
		icon: ShopTwoTone,
		component: Error500
	},
]

export const reportsRoutes: Array<RouterType> = [
	{
		path: '/informes/lista',
		icon: ShopTwoTone,
		component: PageTemplates
	},	
	{
		path: '/informes/info',
		icon: ShopTwoTone,
		component: LocationsSummary
	},
	{
		path: '/informes/resenas',
		icon: ShopTwoTone,
		component: ReviewsSummary
	},
	{
		path: '/informes/contenidos',
		icon: ShopTwoTone,
		component: ContentsSummary
	},
	{
		path: '/informes/error403',
		icon: ShopTwoTone,
		component: Error403
	},
	{
		path: '/informes/error404',
		icon: ShopTwoTone,
		component: Error404
	},
	{
		path: '/informes/error500',
		icon: ShopTwoTone,
		component: Error500
	},
]

export const configRoutes: Array<RouterType> = [
	{
		path: '/configurar/invitados',
		icon: IdcardTwoTone,
		component: Guests
	},
	{
		path: '/configurar/permisos',
		icon: IdcardTwoTone,
		component: GuestPermissions
	},	
	{
		path: '/configurar/keywords',
		icon: ProjectTwoTone,
		component: KeyWords
	},
	{
		path: '/configurar/error403',
		icon: ShopTwoTone,
		component: Error403
	},
	{
		path: '/configurar/error404',
		icon: ShopTwoTone,
		component: Error404
	},
	{
		path: '/configurar/error500',
		icon: ShopTwoTone,
		component: Error500
	},	
]

export const adminRoutes: Array<RouterType> = [
	{
		path: '/admin/usuarios',
		icon: IdcardTwoTone,
		component: Users
	},
	{
		path: '/admin/proyectos',
		icon: ProjectTwoTone,
		component: Projects
	},
	{
		path: '/admin/app',
		icon: ProjectTwoTone,
		component: AppUsers
	},
	{
		path: '/admin/error403',
		icon: ShopTwoTone,
		component: Error403
	},
	{
		path: '/admin/error404',
		icon: ShopTwoTone,
		component: Error404
	},
	{
		path: '/admin/error500',
		icon: ShopTwoTone,
		component: Error500
	},	
]

export const techRoutes: Array<RouterType> = [
	{
		path: '/tech/services',
		icon: IdcardTwoTone,
		component: Services
	},
	{
		path: '/tech/jobs',
		icon: IdcardTwoTone,
		component: MonitoringJobs
	},
	{
		path: '/tech/externalWebs',
		icon: IdcardTwoTone,
		component: ExternalWebs
	},
	{
		path: '/tech/error403',
		icon: ShopTwoTone,
		component: Error403
	},
	{
		path: '/tech/error404',
		icon: ShopTwoTone,
		component: Error404
	},
	{
		path: '/tech/error500',
		icon: ShopTwoTone,
		component: Error500
	},
	
]

export const notificationsRoutes: Array<RouterType> = [
	{
		path: '/notificaciones',
		component: Notifications,
		viewHeader: true,	
		isAuthenticated: true,	
	},	
	{
		path: '/notificaciones/error403',
		icon: ShopTwoTone,
		component: Error403
	},
	{
		path: '/notificaciones/error404',
		icon: ShopTwoTone,
		component: Error404
	},
	{
		path: '/notificaciones/error500',
		icon: ShopTwoTone,
		component: Error500
	},
	
]

export const loginRoutes: Array<RouterType> = [	
	{
		path: '/google-login',
		component: GoogleLogin,
		exact: true
	}
]

export const routes: Array<RouterType> = [	
	{
		path: '/welcome',
		viewHeader: true,	
		isAuthenticated: true,
		exact: true,
		component: Welcome,
	},
	{
		path: '/',
		component: Home,
		viewHeader: true,	
		isAuthenticated: true,
		exact: true,
	},	
	{
		path: '/login',
		component: Login,
		viewHeader: false,
		isAuthenticated: false,
		exact: true
	},
	{
		path: '/informes',
		component: ReportsLayout,
		viewHeader: true,	
		isAuthenticated: true,	
	},
	{
		path: '/negocios',
		component: GoogleBizLayout,
		viewHeader: true,		
		isAuthenticated: true,
	},
	{
		path: '/contenidos',
		component: ContentsLayout,
		viewHeader: true,		
		isAuthenticated: true,
	},
	{
		path: '/clientes',
		component: GoogleClientsLayout,
		viewHeader: true,	
		isAuthenticated: true,	
	},
	{
		path: '/informes',
		component: ReportsLayout,
		viewHeader: true,	
		isAuthenticated: true,	
	},	
	{
		path: '/configurar',
		component: ConfigLayout,
		viewHeader: true,	
		isAuthenticated: true,	
	},
	{
		path: '/tech',
		component: TechLayout,
		viewHeader: true,	
		isAuthenticated: true,	
	},
	{
		path: '/notificaciones',
		component: NotificationsLayout,
		viewHeader: true,	
		isAuthenticated: true,	
	},	
	{
		path: '/admin',
		component: AdminLayout,
		viewHeader: true,	
		isAuthenticated: true,	
	},
	{
		path: '/public/register/*',
		component: UserStep1Register,
		viewHeader: true,
		isAuthenticated: false
	},
	{
		path: '/public/invite/guest/*',
		component: GuestRegister,
		viewHeader: true,
		isAuthenticated: false
	},
	{
		path: '/public/recover/*',
		component: RecoverPassword,
		viewHeader: false,
		isAuthenticated: false
	},
	{
		path: '/vincular',
		component: UserStep2Vincular,
		viewHeader: false,
		isAuthenticated: true
	},
	{
		path: '/public/reports/:id',
		component: PublicReport,
		viewHeader: false,
		isAuthenticated: false
	},
	{
		path: '/error403',
		component: Error403,
		viewHeader: false,
		isAuthenticated: true
	}
	,
	{
		path: '/error404',
		component: Error404,
		viewHeader: false,
		isAuthenticated: true
	}
	,
	{
		path: '/error500',
		component: Error500,
		viewHeader: false,
		isAuthenticated: true
	},
	{
		path: '/home',
		viewHeader: true,	
		isAuthenticated: true,
		exact: true,
		component: Home,
	},
]