import { DatePicker, Form } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

const FormItemEvent = () => {		

  const { t } = useTranslation();

  return (		
	<Form.Item label={t('view.forms.fields.dates.label')} name='dateRange' rules={[{ required: true, message: 'Fechas de evento obligatorias' }]}>
		<DatePicker.RangePicker/>		
	</Form.Item>  	
	)
}

export default FormItemEvent;